import {useControllerProps} from './Widget/ControllerContext';
import React, {useEffect} from 'react';
import {SlotsPlaceholder} from '@wix/widget-plugins-ooi';
import {SlotId} from '../../domain/utils/slotId';

export const CheckoutSlot = /* istanbul ignore next: test slot */ ({slotId}: {slotId: SlotId}) => {
  const {
    slotsStore: {setSlotsParams, setStepId},
    stepsManagerStore: {activeStep},
  } = useControllerProps();

  useEffect(
    () => {
      setSlotsParams(slotId);
    },
    /* eslint-disable react-hooks/exhaustive-deps*/ []
  );

  useEffect(() => {
    setStepId(slotId, activeStep.stepId);
  }, [activeStep]);

  return <SlotsPlaceholder slotId={slotId} defaultHeight={100} />;
};
