import {useTranslation} from '@wix/yoshi-flow-editor';
import {useEffect} from 'react';

export function useI18nWithoutEscapeValue() {
  const {t, i18n} = useTranslation();

  useEffect(() => {
    void i18n.init({
      interpolation: {
        escapeValue: false,
      },
    });
  }, [i18n]);

  return t;
}
