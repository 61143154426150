import {useControllerProps} from '../../../../Widget/ControllerContext';
import {BillingAddressTitle} from '../BillingAddressTitle/BillingAddressTitle';
import {BillingSameAsShippingCheckbox} from './BillingSameAsShippingCheckbox';
import {BillingDetailsForm} from './BillingDetailsForm/BillingDetailsForm';
import React from 'react';

export const VisitorBillingDetails = () => {
  const {
    checkoutStore: {isShippingFlow},
  } = useControllerProps();

  return (
    <>
      <BillingAddressTitle />
      {isShippingFlow && <BillingSameAsShippingCheckbox />}
      <BillingDetailsForm />
    </>
  );
};
