import React, {useEffect} from 'react';
import {useControllerProps} from '../../../../Widget/ControllerContext';
import {BillingAddressDropdown} from '../BillingAddressDropdown/BillingAddressDropdown';
import {ContactAndAddressSummary} from '../../../../ContactAndAddressSummary/ContactAndAddressSummary';
import {useBillingData} from '../WithBillingData/WithBillingData';
import {MemberAddressStepState} from '../../../../../../types/app.types';
import {MemberBillingAddressTitle} from '../BillingAddressTitle/MemberBillingAddressTitle';

export enum MemberBillingDetailsDataHook {
  root = 'MemberBillingDetails.root',
}

export const MemberBillingDetails = () => {
  const {
    memberStore: {defaultAddress, fetchAddresses},
  } = useControllerProps();

  useEffect(
    () => {
      void fetchAddresses();
    },
    /* eslint-disable react-hooks/exhaustive-deps*/ []
  );
  const {currentBillingInfo, onSetCurrentBillingInfoChange, memberAddressStepState} = useBillingData();

  if (!currentBillingInfo) {
    // TODO: deal with empty default address
    onSetCurrentBillingInfoChange(defaultAddress);
  }

  return (
    <>
      <MemberBillingAddressTitle />
      <div data-hook={MemberBillingDetailsDataHook.root}>
        {memberAddressStepState === MemberAddressStepState.OPEN && <BillingAddressDropdown />}
        <ContactAndAddressSummary contact={currentBillingInfo?.contact} address={currentBillingInfo?.address} />
      </div>
    </>
  );
};
