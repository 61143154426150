import React, {useEffect, useState} from 'react';
import {useLocaleKeys} from '../../../../../../locale-keys/LocaleKeys';
import {NextStepButton} from '../../../StepsManager/Components/NextStepButton/NextStepButton';
import {useControllerProps} from '../../../../Widget/ControllerContext';
import {classes} from './CustomerDetailsOpen.st.css';
import {EmailForm} from '../../../../Form/EmailForm/EmailForm';
import {ContactForm} from '../../../../Form/ContactForm/ContactForm';
import {AdditionalInfoForm} from '../../../../Form/AdditionalInfoForm/AdditionalInfoForm';
import {CustomerDetailsStepDataHook} from '../CustomerDetailsStep';
import {StatesButtonStates} from 'wix-ui-tpa';
import {PickupInfoBox} from '../../../../PickupInfoBox/PickupInfoBox';
import {FormLoader} from '../../../../Form/FormLoader/FormLoader';
import {FIELDS_GAP, SPECS} from '../../../../constants';
import {useCustomerDetailsData} from '../WithCustomerDetailsData/WithCustomerDetailsData';
import {AddressForm} from '../../../../Form/AddressForm/AddressForm';
import {useExperiments} from '@wix/yoshi-flow-editor';

export enum CustomerDetailsDataHook {
  emailForm = 'CustomerDetails.emailForm',
  contactForm = 'CustomerDetails.contactForm',
  additionalInfoForm = 'CustomerDetails.additionalInfoForm',
  addressForm = 'CustomerDetails.addressForm',
}

export const CustomerDetailsOpen = () => {
  const {experiments} = useExperiments();
  const localeKeys = useLocaleKeys();
  const {
    checkoutStore: {submitCustomerDetails, checkout, updateCheckoutError, isShippingFlow},
    checkoutSettingsStore: {checkoutSettings},
    formsStore: {areFormsLoaded},
    memberStore: {isMember},
  } = useControllerProps();

  const {
    contactFormValues,
    setContactFormValues,
    contactFormViewer,
    setContactFormErrors,
    contactFormErrors,
    setEmailFormValues,
    emailFormValues,
    emailFormViewer,
    setEmailFormErrors,
    emailFormErrors,
    setAdditionalInfoFormValues,
    additionalInfoFormValues,
    additionalInfoFormViewer,
    setAdditionalInfoFormErrors,
    additionalInfoFormErrors,
    setAddressFormValues,
    addressFormValues,
    addressFormViewer,
    setAddressFormErrors,
    addressFormErrors,
    areFormsValid,
    getContactDetailsFormValues,
    getEmailFormValues,
    getCustomFieldFormValues,
    getAddressFormValues,
  } = useCustomerDetailsData();

  const [buttonState, setButtonState] = useState(StatesButtonStates.IDLE);
  const [buttonDisabledState, setButtonDisabledState] = useState(!areFormsLoaded);

  useEffect(() => {
    if (updateCheckoutError) {
      setButtonState(StatesButtonStates.IDLE);
      setButtonDisabledState(false);
    }
  }, [updateCheckoutError]);

  useEffect(() => {
    setButtonDisabledState(!areFormsLoaded);
  }, [areFormsLoaded]);

  const validateAndSubmit = async () => {
    setButtonDisabledState(true);
    setButtonState(StatesButtonStates.IN_PROGRESS);

    const isValid = await areFormsValid();

    if (isValid) {
      const customFieldValue = getCustomFieldFormValues();
      const contactDetails = getContactDetailsFormValues();
      const email = getEmailFormValues();
      const shippingAddress = isShippingFlow ? getAddressFormValues() : undefined;
      void submitCustomerDetails({
        contactDetails,
        email,
        customFieldValue,
        shippingAddress,
      });
    } else {
      setButtonDisabledState(false);
      setButtonState(StatesButtonStates.IDLE);
    }
  };

  return (
    <div data-hook={CustomerDetailsStepDataHook.open} className={classes.root}>
      {!areFormsLoaded && <FormLoader />}
      {areFormsLoaded && (
        <div
          className={classes.forms}
          style={{
            gap: `${FIELDS_GAP}px`,
          }}>
          {!isMember && (
            <div data-hook={CustomerDetailsDataHook.emailForm}>
              <EmailForm
                formRef={emailFormViewer}
                formValues={emailFormValues}
                formErrors={emailFormErrors}
                setFormValues={setEmailFormValues}
                setFormErrors={setEmailFormErrors}
              />
            </div>
          )}
          <div data-hook={CustomerDetailsDataHook.contactForm}>
            <ContactForm
              formRef={contactFormViewer}
              formValues={contactFormValues}
              formErrors={contactFormErrors}
              setFormValues={setContactFormValues}
              setFormErrors={setContactFormErrors}
            />
          </div>
          {checkoutSettings.customField.show && (
            <div data-hook={CustomerDetailsDataHook.additionalInfoForm}>
              <AdditionalInfoForm
                formRef={additionalInfoFormViewer}
                formValues={additionalInfoFormValues}
                formErrors={additionalInfoFormErrors}
                setFormValues={setAdditionalInfoFormValues}
                setFormErrors={setAdditionalInfoFormErrors}
              />
            </div>
          )}
          {isShippingFlow && (
            <div data-hook={CustomerDetailsDataHook.addressForm}>
              <AddressForm
                formRef={addressFormViewer}
                formValues={addressFormValues}
                formErrors={addressFormErrors}
                setFormValues={setAddressFormValues}
                setFormErrors={setAddressFormErrors}
              />
            </div>
          )}
        </div>
      )}
      {checkout.selectedShippingOption?.pickupInfo &&
        !experiments.enabled(SPECS.UseNewCheckoutInRestOfVisitorFlows) && (
          <PickupInfoBox pickupInfo={checkout.selectedShippingOption.pickupInfo} />
        )}
      <NextStepButton
        onClick={() => void validateAndSubmit()}
        text={localeKeys.checkout.continue_button_label()}
        dataHook={CustomerDetailsStepDataHook.continueButton}
        disabled={buttonDisabledState}
        buttonState={buttonState}
      />
    </div>
  );
};
