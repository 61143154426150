import React, {useEffect, useState} from 'react';
import {useLocaleKeys} from '../../../../../../locale-keys/LocaleKeys';
import {NextStepButton} from '../../../StepsManager/Components/NextStepButton/NextStepButton';
import {useControllerProps} from '../../../../Widget/ControllerContext';
import {classes} from './DeliveryMethodOpen.st.css';
import {DeliveryMethodStepDataHook} from '../DeliveryMethodStep';
import {StatesButtonStates} from 'wix-ui-tpa';
import {DeliveryMethodSection} from '../../../../DeliveryMethodSection/DeliveryMethodSection';

export const DeliveryMethodOpen = () => {
  const localeKeys = useLocaleKeys();
  const {
    deliveryMethodStore,
    checkoutStore: {onDeliveryMethodContinue},
  } = useControllerProps();
  const {isUpdatingShippingOption, canShipToDestination} = deliveryMethodStore;
  const [buttonState, setButtonState] = useState(StatesButtonStates.IDLE);
  const [buttonDisabledState, setButtonDisabledState] = useState(!canShipToDestination);

  useEffect(() => {
    setButtonDisabledState(isUpdatingShippingOption);
  }, [isUpdatingShippingOption]);

  const submit = () => {
    setButtonDisabledState(true);
    setButtonState(StatesButtonStates.IN_PROGRESS);
    onDeliveryMethodContinue();
  };

  return (
    <div data-hook={DeliveryMethodStepDataHook.open} className={classes.root}>
      <DeliveryMethodSection />
      <NextStepButton
        onClick={() => void submit()}
        text={localeKeys.checkout.continue_button_label()}
        dataHook={DeliveryMethodStepDataHook.continueButton}
        disabled={buttonDisabledState}
        buttonState={buttonState}
      />
    </div>
  );
};
