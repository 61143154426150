import React, {FC} from 'react';
import {WidgetProps} from '@wix/yoshi-flow-editor';
import {ControllerContext} from './ControllerContext';
import {ControllerProps} from '../../../types/app.types';
import {LocaleKeysProvider} from '../../../locale-keys/LocaleKeys';
import CheckoutApp from '../CheckoutApp/CheckoutApp';
import {withSlotsPlaceholders} from '@wix/widget-plugins-ooi';
import {withCheckoutThemeOverride} from '../WithCheckoutThemeOverride/WithCheckoutThemeOverride';
import {withStyles} from '@wix/native-components-infra';
import {FormViewerContext} from '@wix/form-viewer/widget';
import './index.css';
import {withExpressCheckoutButtons} from '@wix/ecom-platform-express-checkout-buttons';
import {withPaymentsApi} from '../WithPaymentsApi/WithPaymentsApi';
import {withFunctionObservationResult} from '@wix/function-result-observation';
import {useI18nWithoutEscapeValue} from '../Hooks/useI18nWithoutEscapeValue';

const Widget: FC<WidgetProps<ControllerProps>> = (controllerProps: WidgetProps<ControllerProps>) => {
  const t = useI18nWithoutEscapeValue();

  return (
    <ControllerContext value={controllerProps}>
      <LocaleKeysProvider translateFn={t}>
        <FormViewerContext {...controllerProps}>
          <CheckoutApp host={controllerProps.host} ravenUserContextOverrides={{}} />
        </FormViewerContext>
      </LocaleKeysProvider>
    </ControllerContext>
  );
};

export default withFunctionObservationResult(
  withPaymentsApi(
    withExpressCheckoutButtons(withCheckoutThemeOverride(withStyles(withSlotsPlaceholders(Widget), {strictMode: true})))
  )
);
