import {Dropdown, DropdownOptionProps, DropdownTheme} from 'wix-ui-tpa';
import React from 'react';
import {useLocaleKeys} from '../../../../../../locale-keys/LocaleKeys';
import {useControllerProps} from '../../../../Widget/ControllerContext';
import {useBillingData} from '../WithBillingData/WithBillingData';
import {mapToAddressWithContactModel} from '../../../../../../domain/utils/billingDetails.utils';

export enum BillingAddressDropdownDataHook {
  root = 'BillingAddressDropdown.root',
  dropdown = 'BillingAddressDropdown.dropdown',
}

export const BillingAddressDropdown = () => {
  const {
    memberStore: {addressesInfo},
  } = useControllerProps();

  const localeKeys = useLocaleKeys();
  const {onSetCurrentBillingInfoChange} = useBillingData();

  const dropdownOptions = addressesInfo?.addresses?.map((address) => {
    return {
      id: address.id,
      value: address.addressLine1,
      subtitle: `${address.fullName.firstName} ${address.fullName.lastName}`,
      isSelectable: true,
    };
  });
  const onSelect = (selectedOption: DropdownOptionProps) => {
    const selectedAddress = addressesInfo?.addresses?.find((address) => address.id === selectedOption.id);
    selectedAddress && onSetCurrentBillingInfoChange(mapToAddressWithContactModel(selectedAddress));
  };

  return (
    <div data-hook={BillingAddressDropdownDataHook.root}>
      <Dropdown
        data-hook={BillingAddressDropdownDataHook.dropdown}
        label={localeKeys.chooseAddressModal.billing_address_title()}
        // JTRB - member
        // initialSelectedId={addressesInfo.defaultAddressId}
        upgrade={true}
        theme={DropdownTheme.Box}
        options={dropdownOptions}
        onChange={(option) => onSelect(option)}
      />
    </div>
  );
};
