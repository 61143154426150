import {TextButton} from 'wix-ui-tpa';
import {classes} from './MemberBillingAddressTitle.st.css';
import React, {useEffect, useState} from 'react';
import {useLocaleKeys} from '../../../../../../locale-keys/LocaleKeys';
import {useBillingData} from '../WithBillingData/WithBillingData';
import {MemberAddressStepState, StepId} from '../../../../../../types/app.types';
import {BillingAddressTitle} from './BillingAddressTitle';
import {useControllerProps} from '../../../../Widget/ControllerContext';

export enum MemberBillingAddressTitleDataHook {
  changeButton = 'MemberBillingAddressTitle.changeButton',
}

export const MemberBillingAddressTitle = () => {
  const localeKeys = useLocaleKeys();
  const {memberAddressStepState, setMemberAddressStepState} = useBillingData();
  const {
    stepsManagerStore: {activeStep},
  } = useControllerProps();

  const [shouldShowChangeButton, setShouldShowChangeButton] = useState(
    activeStep.stepId === StepId.payment && memberAddressStepState === MemberAddressStepState.COLLAPSED
  );

  useEffect(
    () => {
      if (
        shouldShowChangeButton !==
        (activeStep.stepId === StepId.payment && memberAddressStepState === MemberAddressStepState.COLLAPSED)
      ) {
        setShouldShowChangeButton(
          activeStep.stepId === StepId.payment && memberAddressStepState === MemberAddressStepState.COLLAPSED
        );
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeStep, memberAddressStepState]
  );

  return (
    <div className={classes.titleContainer}>
      <BillingAddressTitle />
      {shouldShowChangeButton && (
        <TextButton
          key={'textButton'}
          data-hook={MemberBillingAddressTitleDataHook.changeButton}
          className={classes.changeTitle}
          onClick={() => setMemberAddressStepState(MemberAddressStepState.OPEN)}>
          {localeKeys.checkout.change()}
        </TextButton>
      )}
    </div>
  );
};
